import CountUp from "react-countup";

const CardWidget = ({ loading = false, name, count, color }) => {
  return (
    <div className="col-12 md:col-6 p-3 lg:col rounded-md shadow-2 " style={{ borderRadius: '6px' }}>
      <div className=" flex flex-1 w-full justify-between">
        <div className=" text-sm w-full">
          <div className="font-medium text-xs p-0 m-0">{name}</div>
          <div className="flex py-2 justify-content-between align-items-center">
            {loading ? (
              <span className="fadein animation-duration-1000 animation-iteration-infinite text-xs">
                ...
              </span>
            ) : (
              <span className="text-xl font-bold text-wrap w-full break-all">
                <CountUp end={count} />
              </span>
            )}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath={`url(#1${color})`}>
                <rect width="24" height="24" fill={`${color}`} />
                <path
                  d="M3.33325 4.66666H4.66659V5.99999H3.33325V4.66666Z"
                  fill="white"
                />
                <path d="M6 4.66666H7.33333V5.99999H6V4.66666Z" fill="white" />
                <path
                  d="M8.66675 4.66666H10.0001V5.99999H8.66675V4.66666Z"
                  fill="white"
                />
                <path
                  d="M21.3333 2.66666H2.66659C2.31296 2.66666 1.97382 2.80713 1.72378 3.05718C1.47373 3.30723 1.33325 3.64637 1.33325 3.99999V20C1.33325 20.3536 1.47373 20.6927 1.72378 20.9428C1.97382 21.1928 2.31296 21.3333 2.66659 21.3333H21.3333C21.6869 21.3333 22.026 21.1928 22.2761 20.9428C22.5261 20.6927 22.6666 20.3536 22.6666 20V3.99999C22.6666 3.64637 22.5261 3.30723 22.2761 3.05718C22.026 2.80713 21.6869 2.66666 21.3333 2.66666ZM2.66659 3.99999H21.3333V6.79999H2.66659V3.99999ZM2.66659 20V7.86666H21.3333V20H2.66659Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id={`1${color}`}>
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="flex align-items-center">
            <svg
              width="96"
              height="5"
              viewBox="0 0 96 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="96" height="5" rx="2.5" fill={`url(#${color})`} />
              <defs>
                <radialGradient
                  id={`${color}`}
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(48 2.5) rotate(90) scale(2.5 112.412)"
                >
                  <stop stopColor={`${color}`} />
                  <stop
                    offset="0.62645"
                    stopColor={`${color}`}
                    stopOpacity="0"
                  />
                </radialGradient>
              </defs>
            </svg>
            <svg
              width="7"
              height="7"
              viewBox="0 0 7 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="3.5" cy="3.5" r="3.5" fill={`${color}`} />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWidget;
