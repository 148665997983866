import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const BirthRegistrationByGender = ({ data }) => {

    const COLORS = ["#923A3A", "#EC9797"]; // Male, Female colors respectively

    // Mapping the data to work with the Pie component
    const formattedData = data.map((item) => ({
        name: item.gender,
        value: parseInt(item.registration_count, 10), // Convert registration_count to number
    }));

    return (
        <div className="p-3 flex flex-column align-items-center">
            <PieChart width={200} height={200}>
                <Pie
                    data={formattedData}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    outerRadius={60}
                    fill="#8884d8"
                    label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
                >
                    {formattedData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
            <div className="w-full mt-3">
                {formattedData.map((entry, index) => (
                    <div key={index} className="text-left flex align-items-center">
                        <span
                            className="inline-block mr-2"
                            style={{
                                backgroundColor: COLORS[index],
                                width: "12px",
                                height: "12px",
                                borderRadius: "50%",
                            }}
                        />
                        <div>
                            <div className="text-xs">
                                {entry.name}: <span className="font-bold">{entry.value}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BirthRegistrationByGender;
