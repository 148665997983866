import Tabs from "./Tabs";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import BirthNotifications from "./BirthNotifications";


const NotificationsComponent = () => {
    const tabData = [
        {
            id: 1,
            label: "Birth",
            content: <>
                <BirthNotifications />
            </>,
        },
        {
            id: 2,
            label: "Divorce",
            content: <>
                Divorce Content here
            </>,
            disabled: true,
        },
        {
            id: 3,
            label: "Marriage",
            content: <>
                Marriage Content here
            </>,
            disabled: true,

        },
        {
            id: 4,
            label: "Migration",
            content: <>
                Migration Content here
            </>,
            disabled: true,
        },
    ];
    const navigate = useNavigate();


    return (
        <>
            <div className="flex justify-content-between align-items-center px-5 shadow-2 relative lg:sticky top-0 left-0 border-bottom-1 surface-border z-1" style={{ height: "63px", backgroundColor: "#0E7706" }}>
                <div className="flex">
                    <p className="hidden lg:block text-white font-bold poppins-font">Notifications</p>
                </div>
            </div>

            <div className="flex justify-content-between align-items-center px-4 mb-3 mt-2">
                <div>
                </div>
                <Button
                    type="button"
                    className="p-button-primary mr-2"
                    label="Notifications Statistics by States"
                    onClick={() => navigate('/state-commissioner/notifications-by-states')}
                />
            </div>
            <Tabs tabs={tabData} />
        </>
    );
};

export default NotificationsComponent;