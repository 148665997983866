import React, { useEffect, useState } from 'react';
import { Button } from "primereact/button";

const Tabs = ({ tabs }) => {
  // const [activeTab, setActiveTab] = useState(tabs[0]?.id); // Default to the first tab
  const savedTab = sessionStorage.getItem("selectedTab");
  const [activeTab, setActiveTab] = useState(Number(savedTab)); // Default to the first tab

  // Retrieve selected tab from sessionStorage when component mounts
  useEffect(() => {
    const savedTab = sessionStorage.getItem("selectedTab");
    if (savedTab) {
      setActiveTab(Number(savedTab)); // Use the saved tab from sessionStorage
    }
  }, []);

  // Handle tab change and save the selected tab to sessionStorage
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    sessionStorage.setItem("selectedTab", tabId.toString()); // Save to sessionStorage
  };

  return (
    <>
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 list-none -mt-6">
        {tabs.map((tab) => (
          <li key={tab.id} className="">
            {tab.disabled ? (
              <span className="inline-block px-6 py-2 text-gray-400 cursor-not-allowed border border-gray-300 rounded-lg border">
                {tab.label}
              </span>
            ) : (
              <Button
                className={`inline-block px-6 py-2 mx-1 border rounded-lg transition-colors duration-300 ${activeTab === tab.id
                  ? "text-white bg-green-600 border-green-600"
                  : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-primary hover:shadow-1 hover:border-green-200"
                  }`}
                onClick={() => handleTabChange(tab.id)}
              > 
                {tab.label}
              </Button>
            )}
          </li>
        ))}
      </ul>

      {/* Tab content */}
      <div className="mt-4">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`${activeTab === tab.id ? "block" : "hidden"
              } transition-opacity duration-300`}
          >
            <div className="grid mb-3 px-5">
              {tab.content}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Tabs;