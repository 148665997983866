import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Attestation from "./pages/Attestation";
import Death from "./pages/Death";
import "./App.scss";
import Unauthorized from "./pages/Unauthorized";
import Login from "./pages/Login";
import SingleApplication from "./pages/SingleApplication";
import Admin from "./pages/Admin";
import ProtectedRouteAdmin from "./pages/ProtectedRouteAdmin";
import Homez from "./components/Homez";
import AdminFlagged from "./pages/AdminFlagged";
import AdminVerified from "./pages/AdminVerified";
import AdminVerified2 from "./pages/AdminVerified2";
import AdminVerified3 from "./pages/AdminVerified3";
import Main from "./pages/Main";
import BirthRegistrations from "./pages/StateCommissioner/BirthRegistrations";
import BirthReg from "./pages/BirthReg";
import BirthReport from "./pages/BirthReport";
import CertPending from "./pages/CertPending";
import BirthRegState from "./pages/BirthRegState";
import BirthRegLga from "./pages/BirthRegLga";
import PendingHospital from "./pages/PendingHospital";
import SingleHospital from "./pages/SingleHospital";
import SingleApproved from "./pages/SingleApproved";
import SingleApprovedOrg from "./pages/SingleApprovedOrg";
import PublicHos from "./pages/PublicHos";
import PrivateHos from "./pages/Private";
import Registrars from "./pages/Registrars";
import RegList from "./pages/RegistrarsList";
import SingleRegistrars from "./pages/Single-registrars";
import NewReg from "./pages/NewReg";
import PendingCert from "./pages/PendingCert";
import Still from "./pages/Still";
import BirthCenter from "./pages/BirthCenter";
import DeathReg from "./pages/DeathReg";
import DeathRegState from "./pages/DeathRegState";
import DeathRegLga from "./pages/DeathRegLga";
import DeathCenter from "./pages/DeathCenter";
import SingleApplication2 from "./pages/SingleApplication2";
import AttestReg from "./pages/AttestReg";
import AttestRegLga from "./pages/AttestRegLga";
import AttestCenter from "./pages/AttestCenter";
import AttestRegState from "./pages/AttestRegState";
import StillReg from "./pages/StillReg";
import StillRegState from "./pages/StillRegState";
import StillRegLga from "./pages/StillRegLga";
import StillCenter from "./pages/StillCenter";
import AdminVerified4 from "./pages/AdminVerified4";
import SingleApplication3 from "./pages/SingleApplication3";
import SingleApplication4 from "./pages/SingleApplication4";
import Dcr from "./pages/Dcr";
import DcrList from "./pages/DcrList";
import NewReg2 from "./pages/NewReg2";
import RegistrarsCp from "./pages/RegistrarsCp";
import NewRegg from "./pages/NewRegg";
import ModBirth from "./pages/ModBirth";
import ModDeath from "./pages/ModDeath";
import ModAttest from "./pages/ModAttest";
import UpdateUser from "./pages/UpdateUser";
import Admins from "./pages/Admins";
import NewAdmin from "./pages/NewAdmin";
import SingleDcr from "./pages/Single-dcr";
import AdhocList from "./pages/AdhocList";

import Verification from "./pages/Verification";
import Verification2 from "./pages/Verification2";
import Transact from "./pages/Transact";
import SingleAttestMod from "./pages/SingleAttestMod";
import SingleBirthMod from "./pages/SingleBirthMod";
import SingleDeathMod from "./pages/SingleDeathMod";
import AllBirthRegistrations from "./pages/ApprovalAccess/AllBirthRegistrations";
import UserAccess from "./pages/UserAccess";
import CreateUser from "./pages/UserAccess/createUser";
import ViewUser from "./pages/UserAccess/viewUser";
import PermissionAccess from "./helpers/utils/allowedRoles";
import { Permissions } from "./helpers/constants";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import ViewSingleUser from "./pages/ApprovalAccess/viewSingleUser";
import StartingPage from "./pages/startingPage";
import AllDeathRegistrations from "./pages/ApprovalAccess/AllDeathRegistrations";
import ViewSingleDeathRegistration from "./pages/ApprovalAccess/viewSingleDeath";
import AllAttestationRegistrations from "./pages/ApprovalAccess/AllAttestationregistrations";
import ViewSingleAttestation from "./pages/ApprovalAccess/viewSingleAttestation";
import AllBirthModifications from "./pages/ApprovalAccess/AllBirthModifications";
import ViewSingleBirthModification from "./pages/ApprovalAccess/viewSingleBirthModification";
import AllDeathModifications from "./pages/ApprovalAccess/AllDeathModifications";
import AllAttestationModifications from "./pages/ApprovalAccess/AllAttestationModification";
import ViewSingleAttestationModification from "./pages/ApprovalAccess/viewSingleAttestationModification";
import ViewSingleDeathModification from "./pages/ApprovalAccess/viewSingleDeathModification";
import AllBirthNotifications from "./pages/ApprovalAccess/AllBirthNotification";
import ViewSingleBirthNotification from "./pages/ApprovalAccess/viewSingleBirthNotification";
import AllAttestationWithoutNIN from "./pages/ApprovalAccess/AllAttestationWithoutNIN";
import ViewSingleAttestationWithoutNin from "./pages/ApprovalAccess/viewSingleAttestationwithoutNIn";
import StillBirthRegistrations from "./pages/StateCommissioner/StillBirthRegistrations";
import BirthStatsByStates from "./pages/StateCommissioner/BirthStatByStates";
import StillBirthStatsByStates from "./pages/StateCommissioner/StillBirthStatByStates";
import DeathRegistrations from "./pages/StateCommissioner/DeathRegistrations";
import DeathStatsByStates from "./pages/StateCommissioner/DeathStatByStates";
import BirthAttestation from "./pages/StateCommissioner/BirthAttestation";
import BirthAttestationByStates from "./pages/StateCommissioner/BirthAttestationByStates";
import NotificationsComponent from "./pages/StateCommissioner/NotificationsComponent";
import NotificationsByStates from "./pages/StateCommissioner/NotificationsByStates";
import ModificationsComponent from "./pages/StateCommissioner/ModificationsComponent";
import ModificationsByStates from "./pages/StateCommissioner/ModificationsByStates";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRouteAdmin>
              <Homez />
            </ProtectedRouteAdmin>
          }
        >
          <Route index element={<StartingPage />} />
          <Route path="dashboard" element={<Main />} />
          <Route path="/state-commissioner/birth-registrations" element={<BirthRegistrations />} />
          <Route path="/state-commissioner/still-birth-registrations" element={<StillBirthRegistrations />} />
          <Route path="/state-commissioner/birth-registrations-by-states" element={<BirthStatsByStates />} />
          <Route path="/state-commissioner/still-birth-registrations-by-states" element={<StillBirthStatsByStates />} />
          <Route path="/state-commissioner/death-registrations" element={<DeathRegistrations />} />
          <Route path="/state-commissioner/death-registrations-by-states" element={<DeathStatsByStates />} />
          <Route path="/state-commissioner/birth-attestation" element={<BirthAttestation />} />
          <Route path="/state-commissioner/birth-attestation-by-states" element={<BirthAttestationByStates />} />
          <Route path="/state-commissioner/notifications" element={<NotificationsComponent />} />
          <Route path="/state-commissioner/notifications-by-states" element={<NotificationsByStates />} />
          <Route path="/state-commissioner/modifications" element={<ModificationsComponent />} />
          <Route path="/state-commissioner/modifications-by-states" element={<ModificationsByStates />} />

          <Route path="admin-flagged" element={<AdminFlagged />} />
          <Route path="death" element={<Death />} />
          <Route path="birth" element={<Admin />} />
          <Route path="birth/registrations" element={<BirthReg />} />
          <Route
            path="birth/all-registrations"
            element={<AllBirthRegistrations />}
          />
          <Route
            path="death/all-registrations"
            element={<AllDeathRegistrations />}
          />
          <Route
            path="attestation/all-registrations"
            element={<AllAttestationRegistrations />}
          />
          <Route
            path="attestation-without-nin/all-registrations"
            element={<AllAttestationWithoutNIN />}
          />
          <Route
            path="attestation-without-nin/all-registrations/view/:id"
            element={<ViewSingleAttestationWithoutNin />}
          />
          <Route
            path="birth-notifications/all-registrations"
            element={<AllBirthNotifications />}
          />
          <Route
            path="birth-notifications/all-registrations/view/:id"
            element={<ViewSingleBirthNotification />}
          />
          <Route
            path="birth-modifications/all-registrations"
            element={<AllBirthModifications />}
          />
          <Route
            path="birth-modifications/all-registrations/view/:id"
            element={<ViewSingleBirthModification />}
          />
          <Route
            path="death-modifications/all-registrations"
            element={<AllDeathModifications />}
          />
          <Route
            path="death-modifications/all-registrations/view/:id"
            element={<ViewSingleDeathModification />}
          />
          <Route
            path="attestation-modifications/all-registrations"
            element={<AllAttestationModifications />}
          />
          <Route
            path="attestation-modifications/all-registrations/view/:id"
            element={<ViewSingleAttestationModification />}
          />
          <Route
            path="birth/all-registrations/view/:id"
            element={<ViewSingleUser />}
          />
          <Route
            path="death/all-registrations/view/:id"
            element={<ViewSingleDeathRegistration />}
          />
          <Route
            path="attestation/all-registrations/view/:id"
            element={<ViewSingleAttestation />}
          />
          <Route path="birth/state/:id" element={<BirthRegState />} />
          <Route path="birth/lga/:id" element={<BirthRegLga />} />
          <Route path="birth/center/:id" element={<BirthCenter />} />
          <Route path="attestation/registrations" element={<AttestReg />} />
          <Route path="attestation/state/:id" element={<AttestRegState />} />
          <Route path="attestation/lga/:id" element={<AttestRegLga />} />
          <Route path="attestation/center/:id" element={<AttestCenter />} />
          <Route path="verification" element={<Verification />} />
          <Route path="birth-report" element={<BirthReport />} />
          {/* </Route> */}
          <Route path="death" element={<Death />} />
          <Route path="death/registrations" element={<DeathReg />} />
          <Route path="modify/birth/registrations" element={<ModBirth />} />
          <Route path="modify/death/registrations" element={<ModDeath />} />
          <Route path="modify/attest/registrations" element={<ModAttest />} />
          <Route path="death/state/:id" element={<DeathRegState />} />
          <Route path="death/lga/:id" element={<DeathRegLga />} />
          <Route path="death/center/:id" element={<DeathCenter />} />
          <Route path="attestation" element={<Attestation />} />
          <Route path="still" element={<Still />} />
          <Route path="still/registrations" element={<StillReg />} />
          <Route path="still/state/:id" element={<StillRegState />} />
          <Route path="still/lga/:id" element={<StillRegLga />} />
          <Route path="still/center/:id" element={<StillCenter />} />
          <Route path="cert-pending" element={<CertPending />} />
          <Route path="birth/list" element={<AdminVerified />} />
          <Route path="death/list" element={<AdminVerified2 />} />
          <Route path="attestation/list" element={<AdminVerified3 />} />
          <Route path="still/list" element={<AdminVerified4 />} />
          <Route path="hospital/pending" element={<PendingHospital />} />
          <Route path="hospital/public" element={<PublicHos />} />
          <Route path="hospital/private" element={<PrivateHos />} />
          <Route path="single/:id" element={<SingleApplication />} />
          <Route path="single-modattest/:id" element={<SingleAttestMod />} />
          <Route path="single-moddeath/:id" element={<SingleDeathMod />} />
          <Route path="single-modbirth/:id" element={<SingleBirthMod />} />
          <Route path="single-death/:id" element={<SingleApplication2 />} />
          <Route path="single-attest/:id" element={<SingleApplication4 />} />
          <Route path="single-still/:id" element={<SingleApplication3 />} />
          <Route path="single-hospital/:id" element={<SingleHospital />} />
          <Route path="single-approved/:id" element={<SingleApproved />} />
          <Route
            path="single-approved-organization/:id"
            element={<SingleApprovedOrg />}
          />
          <Route path="single-registrars/:id" element={<SingleRegistrars />} />
          <Route path="single-dcr" element={<SingleDcr />} />
          <Route path="registrars" element={<Registrars />} />
          <Route path="directors" element={<RegistrarsCp />} />
          <Route path="dcr" element={<Dcr />} />
          <Route
            path="user-access"
            element={
              <PermissionAccess
                allowedPermissions={[Permissions.canManageUser]}
              >
                <UserAccess />
              </PermissionAccess>
            }
          />
          <Route
            path="user-access/create"
            element={
              <PermissionAccess
                allowedPermissions={[Permissions.canManageUser]}
              >
                <CreateUser />
              </PermissionAccess>
            }
          />
          <Route
            path="user-access/view"
            element={
              <PermissionAccess
                allowedPermissions={[Permissions.canManageUser]}
              >
                <ViewUser />
              </PermissionAccess>
            }
          />
          <Route
            path="user-access/update-user/:id"
            element={
              <PermissionAccess
                allowedPermissions={[Permissions.canManageUser]}
              >
                <UpdateUser />
              </PermissionAccess>
            }
          />
          <Route path="admins" element={<Admins />} />
          <Route path="dcr-list" element={<DcrList />} />
          <Route path="registrars-list" element={<RegList />} />
          <Route path="registrars-new" element={<NewReg />} />
          <Route path="directors-new" element={<NewRegg />} />
          <Route path="admin-new" element={<NewAdmin />} />
          <Route path="dcr-new" element={<NewReg2 />} />
          <Route path="pending-cert" element={<PendingCert />} />
          <Route path="update-user/:id" element={<UpdateUser />} />
          <Route path="adhoc-list" element={<AdhocList />} />
          <Route path="pending-verification" element={<Verification />} />
          <Route path="all-organizations" element={<Verification2 />} />
          <Route path="transaction-report" element={<Transact />} />
          {/* <Route path='single/:id' element={<SingleApplication/>} /> */}
          {/* <Route path='single' element={<SingleApplication/>} /> */}
        </Route>

        <Route path="login" element={<Login />} />
        <Route path="unauth" element={<Unauthorized />} />
        <Route path="unauthorized" element={<UnauthorizedPage />} />
      </Routes>
    </Router>
  );
};

export default App;
