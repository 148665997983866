import Tabs from "./Tabs";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import BirthModifications from "./BirthModifications";
import DeathModifications from "./DeathModifications";
import AttestationModifications from "./AttestationModifications";


const ModificationsComponent = () => {
    const tabData = [
        {
            id: 1,
            label: "Birth",
            content: <>
                <BirthModifications />
            </>,
        },
        {
            id: 2,
            label: "Death",
            content: <>
                <DeathModifications />
            </>,
        },
        {
            id: 3,
            label: "Attestation",
            content: <>
                <AttestationModifications />
            </>,

        },
    ];
    const navigate = useNavigate();


    return (
        <>
            <div className="flex justify-content-between align-items-center px-5 shadow-2 relative lg:sticky top-0 left-0 border-bottom-1 surface-border z-1" style={{ height: "63px", backgroundColor: "#0E7706" }}>
                <div className="flex">
                    <p className="hidden lg:block text-white font-bold poppins-font">Modifications</p>
                </div>
            </div>

            <div className="flex justify-content-between align-items-center px-4 mb-3 mt-2">
                <div>
                </div>
                <Button
                    type="button"
                    className="p-button-primary mr-2"
                    label="Modifications Statistics by States"
                    onClick={() => navigate('/state-commissioner/modifications-by-states')}
                />
            </div>
            <Tabs tabs={tabData} />
        </>
    );
};

export default ModificationsComponent;