import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Roles } from "../helpers/constants";
// const userInfo = JSON.parse(localStorage.getItem('userInfo-admin-v2'))
const ProtectedRouteAdmin = ({ children }) => {
  const { userInfo } = useSelector((state) => state.user);
  return userInfo &&
    [Roles.Admin, Roles.SubAdmin, Roles.TIU,Roles.StateCommisioner].includes(+userInfo.roles?.id) ? (
    children
  ) : (
    <Navigate to="/unauth" />
  );
};

export const userHasPermissions = (requiredPermissions, user) => {
  return requiredPermissions.every((permission) =>
    user?.permission?.permissions.includes(permission)
  );
};

export const modulePermissions = (requiredPermission, user) => {
  // const hasAccess = user?.permission?.permissions.includes(requiredPermission);
const permissionsArray = user?.permission?.permissions.split(",");

const hasAccess = permissionsArray.some(
  (permission) =>
    permission.trim() === requiredPermission
);


  if (hasAccess) {
    return true;
  }
  return false;
};

export default ProtectedRouteAdmin;
