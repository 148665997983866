import React from "react";
import {
    PieChart,
    Pie,
    Cell,
    Legend,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import "primeflex/primeflex.css";

const COLORS = ["#1E3A8A", "#EF4444"];

const ReusablePieChart = ({ data }) => {
    const chartData = data.map((item, index) => ({
        name: item.registration_type,
        value: parseInt(item.registration_count, 10),
        fill: COLORS[index % COLORS.length],
    }));

    const renderLegendText = (value, entry) => {
        const { value: count } = entry.payload;
        return `${value}: ${count.toLocaleString()}`;
    };

    return (
        <div className="p-d-flex p-flex-column p-ai-center">
            <ResponsiveContainer width={250} height={280}>
                <PieChart>
                    <Pie
                        dataKey="value"
                        isAnimationActive={true}
                        data={chartData}
                        outerRadius={60}
                        cx="50%"
                        cy="50%"
                        label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
                    >
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                    </Pie>
                    <Legend
                        verticalAlign="bottom"
                        align="left"
                        iconType="circle"
                        iconSize={15}
                        formatter={renderLegendText} // Add the formatter to customize the legend text
                    />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ReusablePieChart;
