import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Menu.scss';
import { Baby, Hexagon, FilePenLine, Megaphone, FileCog, LogOut } from 'lucide-react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/userSlice';

const Menu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const menuItems = [
        { name: 'Birth Registration', icon: <Baby />, path: '/state-commissioner/birth-registrations' },
        { name: 'Still Birth', icon: <Baby />, path: '/state-commissioner/still-birth-registrations' },
        { name: 'Death Registration', icon: <Hexagon />, path: '/state-commissioner/death-registrations' },
        { name: 'Birth Attestation', icon: <FilePenLine />, path: '/state-commissioner/birth-attestation' },
        // { name: 'Notifications', icon: <Megaphone />, path: '/state-commissioner/notifications' },
        // { name: 'Modifications', icon: <FileCog />, path: '/state-commissioner/modifications' },
        { name: 'Logout', icon: <LogOut />, path: '/' },
    ];

    const handleLogout = () => {
        localStorage.removeItem("userInfo-admin-v2");
        localStorage.removeItem("userInfo-admin-token-v2");
        dispatch(logout());
        navigate("/unauth");
    };

    const confirmLogout = async () => {
        const result = await Swal.fire({
          title: "Confirm",
          text: `Are you sure you want to logout?`,
          showCancelButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Sign Out",
        });
        if (result.isConfirmed) {
          handleLogout();
        }
    };

    // Method to reset the selectedTab to 1 for Notifications and Modifications
    const resetSelectedTab = () => {
        sessionStorage.setItem("selectedTab", "1");
    };

    const handleMenuClick = (item) => (e) => {
        if (item.name === 'Logout') {
            e.preventDefault(); // Prevent default navigation
            confirmLogout();
        } else if (item.name === 'Notifications' || item.name === 'Modifications') {
            resetSelectedTab(); // Reset selectedTab to 1 when Notifications or Modifications are clicked
        }
    };

    return (
        <div className="px-4 mt-2">
            {menuItems.map((item, index) => (
                <NavLink 
                    to={item.path} 
                    key={index}
                    className={({ isActive }) => `menu-item mb-1 ${isActive ? 'active text-white' : ''}`}
                    onClick={handleMenuClick(item)}
                >
                    <div className="icon">{item.icon}</div>
                    <span>{item.name}</span>
                </NavLink>
            ))}
        </div>
    );
};

export default Menu;
