import React, { useState, useEffect } from "react";
import { getDeathCompareStats } from "../../redux/apiCalls";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import StatisticCard from "./StatisticCard";
import { useNavigate } from "react-router-dom";

const DeathStatsByStates = () => {
    const [statsData, setStatsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await getDeathCompareStats();
                setStatsData(response);
            } catch (error) {
                console.error("Error fetching stats data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchStats();
    }, []);

    const renderSkeletons = () => {
        return Array.from({ length: 16 }).map((_, index) => (
            <div className="col-12 md:col-6 lg:col-3" key={index}>
                <div className="surface-0 shadow-2 p-3 border-round-md" style={{ minHeight: '120px', display: 'flex', alignItems: 'center' }}>
                    <div className="flex flex-column justify-content-between">
                        <div className="flex align-items-center mb-2">
                            <Skeleton className="border-round-3xl" width="10rem" height="1.5rem" />
                        </div>
                        <Skeleton className="border-round-3xl" width="5rem" height="2rem" />
                    </div>
                    <div className="ml-auto">
                        <Skeleton shape="circle" size="4rem" />
                    </div>
                </div>
            </div>
        ));
    };

    const renderNoDataMessage = () => {
        return (
            <div className="col-12 text-center">
                <p className="text-xl text-gray-600">No data available</p>
            </div>
        );
    };

    return (
        <>
            <div className="flex justify-content-between align-items-center px-5 shadow-2 relative lg:sticky top-0 left-0 border-bottom-1 surface-border z-1" style={{ height: "63px", backgroundColor: "#0E7706" }}>
                <div className="flex">
                    <p className="hidden lg:block text-white font-bold poppins-font">Death Registration</p>
                </div>
            </div>

            <div className="flex justify-content-between align-items-center px-4 mb-3 mt-2">
                <Button
                    type="button"
                    icon="pi pi-arrow-left"
                    className="p-button-text p-button-secondary mt-2"
                    label="All Death registrations by States"
                    onClick={() => navigate(-1)}
                />
                <div></div>
            </div>

            <div className="grid px-5">
                {isLoading 
                    ? renderSkeletons() 
                    : statsData?.length 
                        ? statsData.map((state) => (
                            <StatisticCard
                                key={state.state}
                                stateName={state.state}
                                content={state.registration_count?.toLocaleString() || "N/A"}
                                stateLogo={`/assets/img/${state.stateid}.png`}
                            />
                          ))
                        : renderNoDataMessage()
                }
            </div>
        </>
    );
};

export default DeathStatsByStates;