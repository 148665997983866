import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
    LabelList
} from "recharts";

// Utility function to find the highest value in the dataset and round up
function getHighestNumberInArray(dataArray) {
    let highest = 0;

    dataArray.forEach(item => {
        const number = parseInt(item.uv, 10);
        const maxInItem = Math.max(number);
        highest = Math.max(highest, maxInItem);
    });

    return Math.ceil(highest / 10) * 10; // Rounded up to nearest 10 for a cleaner axis scale
}

// Utility to calculate the total sum of all values (for percentages)
function calculateTotal(data) {
    return data.reduce((total, current) => total + current.uv, 0);
}

export const HorizontalBarChartComponent = ({ data }) => {
    const maxData = getHighestNumberInArray(data);
    const total = calculateTotal(data); // Calculate total for percentage

    return (
        <ResponsiveContainer width="100%" height={480}>
            <BarChart
                layout="vertical"
                barCategoryGap={"10%"}
                barGap={0}
                data={data}
                margin={{ top: 30, right: 20, left: 0, bottom: 15 }}
            >
                <CartesianGrid horizontal={false} strokeDasharray="7 7" />
                <YAxis
                    type="category"
                    dataKey="name" // Use 'name' from your data
                    tickLine={false}
                    tick={{ fontSize: 14, fill: "#000" }}
                />
                <XAxis
                    type="number"
                    allowDecimals={false}
                    axisLine={false}
                    tickLine={false}
                    tick={{ fontSize: 12 }}
                    domain={[0, maxData]}
                />
                <Tooltip cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} />
                <Bar
                    dataKey="uv" // Use 'uv' from your data
                    fill="#28a745" // Green color for the bars
                    radius={[0, 10, 10, 0]} // Adjusted for horizontal layout
                    barSize={20}
                >
                    {/* Display percentage inside or outside the bar */}
                    <LabelList
                        dataKey="uv"
                        position="right" // Position at the right of the bar
                        content={({ value }) => `${((value / total) * 100).toFixed(0)}%`} // Calculate percentage based on total
                        fill="#000"
                        fontSize={12}
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
