import { useSelector } from "react-redux";
import { modulePermissions } from "../../pages/ProtectedRouteAdmin";

const useModuleAccess = () => {
  const { userInfo: user } = useSelector((state) => state.user);

  const checkModuleAccess = (permission) => {
    if (typeof permission === "object") {
      return permission.some((perm) => modulePermissions(perm, user));
    } else if (typeof permission === "string") {
      return modulePermissions(permission, user);
    }
    return false;
  }

  return {checkModuleAccess};

};

export default useModuleAccess;
