import { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import {
  getAllDeathRegRecords,
  getAllDeathRegStats,
} from "../../redux/apiCalls";
import { getGender } from "../../redux/apiCalls";
import { Dropdown } from "primereact/dropdown";
import CardWidget from "../../components/widgets/cards";
import { Toast } from "primereact/toast";
import { approvalStatus, regTypeFormat, RoleName } from "../../utils";
import FilterHeader from "../../components/FilterHeader";
import {
  selfFilterOptions,
  statusFilterOptions,
} from "../../helpers/constants";

const AllDeathRegistrations = () => {
  const toast = useRef(null);
  const [statisticsLoading, setStatisticsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState(null);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(20);
  const [pagination, setPagination] = useState([]);
  const [filters, setFilters] = useState({
    self: "",
    type: "",
    search: "",
  });

  let pageIndexOffset = 0;
  const pageStartIndex = page > 1 ? (page - 1) * rows : 0;
  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1); // PrimeReact uses 0-based page index
    setRows(event.rows);
    pageIndexOffset = event.first;
  };

  const [registrations, setRegistrations] = useState([]);
  const getStatistics = async () => {
    try {
      const data = await getAllDeathRegStats();
      setStatistics(data);
      setStatisticsLoading(false);
    } catch (error) {
      console.error(error);
      setStatisticsLoading(false);
    }
  };
  const getRecords = async () => {
    setLoading(true);
    try {
      const data = await getAllDeathRegRecords({
        result_per_page: rows,
        page,
        type: filters.type,
        search: filters.search,
        self: filters.self,
      });

      setRegistrations(data?.result);
      setPagination(data?.pagination);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);

  useEffect(() => {
    getRecords();
    // eslint-disable-next-line
  }, [filters, page, rows]);

  const stats = [
    {
      name: "Total Registrations",
      count: statistics?.total,
      color: "#551CF6",
    },
    {
      name: "Pending Registrations",
      count: statistics?.Pending,
      color: "#FFDC27",
    },
    {
      name: "Approved Registrations",
      count: statistics?.Approved,
      color: "#289004",
    },
    {
      name: "Queried Registrations",
      count: statistics?.Queried,
      color: "#FF2727",
    },
  ];
  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`view/${rowData.id}`}
      >
        VIEW
      </Link>
    );
  };
  const genderTemplate = (rowData) => {
    return (
      <p className={`font-bold`}>
        {getGender(rowData.deceasedData?.gender ?? "")}
      </p>
    );
  };
  const dater = (rowData) => {
    return (
      <p className={`font-bold`}>
        {rowData?.date_created?.split("T")[0] ?? ""}
      </p>
    );
  };
  const nameTemplate = (rowData) => {
    return (
      <p className={`font-bold`}>
        {`${rowData.deceasedData?.surname ?? ""} ${rowData.deceasedData?.firstname ?? ""
          } ${rowData.deceasedData?.middle_name ?? ""} `}{" "}
      </p>
    );
  };

  const approvalStatusTemplate = (rowData) => {
    let status;
    switch (+rowData?.approved_status) {
      case approvalStatus.APPROVED:
        status = "Approved";
        break;
      case approvalStatus.PENDING:
        status = "Pending";
        break;
      case approvalStatus.QUERIED:
        status = "Queried";
        break;
      default:
        status = "Unknown";
    }
    return <p>{status}</p>;
  };

  const SN = (rowData) => {
    const sn = registrations.findIndex((items, index) => items === rowData);

    return <p>{pageStartIndex + sn + 1 + pageIndexOffset}</p>;
  };
  const filterGroups = [
    {
      name: "type",
      label: "Status",
      type: "dropdown",
      options: statusFilterOptions,
      optionLabel: "name",
      optionValue: "id",
    },
    {
      name: "self",
      label: "Type",
      type: "dropdown",
      options: selfFilterOptions,
      optionLabel: "name",
      optionValue: "id",
    },
    { name: "search", label: "Search", type: "search" },
  ];
  const handleFilterChange = (updatedFilters) => {
    setFilters(updatedFilters);
  };

  const renderHeader1 = () => {
    return (
      <>
        <p className="capitalize">
          {filters?.status === "" ? "All" : filters?.status ?? "All"}{" "}
          Registrations -{pagination?.total}
        </p>
        <FilterHeader
          filters={filterGroups}
          onFilterChange={handleFilterChange}
        />
      </>
    );
  };
  const header1 = renderHeader1();
  const dt = useRef(null);

  // const exportPdf = () => {
  //   import("jspdf").then((jsPDF) => {
  //     import("jspdf-autotable").then(() => {
  //       const doc = new jsPDF.default(0, 0);

  //       // Get the DataTable container
  //       const dataTableContainer = document.getElementById("p-table");

  //       if (dataTableContainer) {
  //         // Clone the container to avoid modifying the original content
  //         const clonedContainer = dataTableContainer.cloneNode(true);

  //         // Check if the cloned container has a table inside
  //         const table = clonedContainer.querySelector("table");

  //         if (table) {
  //           // Remove undesired elements from the cloned container
  //           const spansToRemove = clonedContainer.querySelectorAll("td span");
  //           spansToRemove.forEach((span) => {
  //             span.parentNode.removeChild(span);
  //           });

  //           // Generate PDF using the modified container
  //           doc.autoTable({ html: table });
  //           doc.save("birth-registrations.pdf");
  //         } else {
  //           console.error("Table element not found in cloned container");
  //         }
  //       } else {
  //         console.error("DataTable container not found");
  //       }
  //     });
  //   });
  // };

  // const exportExcel = () => {
  //   import("xlsx").then((xlsx) => {
  //     // Find the table element by class name
  //     var table_elt = document.querySelector(".p-datatable-table");

  //     // Check if the table element is found
  //     if (table_elt) {
  //       // Clone the table to avoid modifying the original
  //       const clonedContainer = table_elt.cloneNode(true);

  //       // Remove <span> elements within <td> elements
  //       const spansToRemove = clonedContainer.querySelectorAll("td span");
  //       spansToRemove.forEach((span) => {
  //         span.parentNode.removeChild(span);
  //       });

  //       // Convert the modified table to a worksheet
  //       const worksheet = xlsx.utils.table_to_sheet(clonedContainer);

  //       // Create a workbook
  //       const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

  //       // Convert the workbook to Excel format
  //       const excelBuffer = xlsx.write(workbook, {
  //         bookType: "xlsx",
  //         type: "array",
  //       });

  //       // Save the Excel file
  //       saveAsExcelFile(excelBuffer, "birth-registrations");
  //     } else {
  //       console.error("Table element not found");
  //     }
  //   });
  // };
  // const saveAsExcelFile = (buffer, fileName) => {
  //   import("file-saver").then((FileSaver) => {
  //     let EXCEL_TYPE =
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //     let EXCEL_EXTENSION = ".xlsx";
  //     const data = new Blob([buffer], {
  //       type: EXCEL_TYPE,
  //     });
  //     FileSaver.saveAs(
  //       data,
  //       fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
  //     );
  //   });
  // };

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <div className=" mt-2">
        <div className="">
          <div className="mt-3 w-full m-0 grid gap-4 ">
            {stats.map((item, index) => {
              const { name, color, count } = item;
              return (
                <CardWidget
                  loading={statisticsLoading}
                  key={index}
                  name={name}
                  color={color}
                  count={count}
                />
              );
            })}
          </div>

          <div className="card mt-6 p-3 ">
            {/* <div className="mb-3 flex align-items-center justify-content-between p-3">
              <Button
                label="Go back"
                icon="pi pi-arrow-left"
                className="p-button-sm"
                onClick={goBack}
              />
              <span className="text-xl font-medium text-900">
                Registration List
              </span>
              <div className="flex align-items-center export-buttons">
                <Button
                  type="button"
                  icon="pi pi-file-excel"
                  onClick={exportExcel}
                  className="p-button-success mr-2"
                  data-pr-tooltip="XLS"
                />
                <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  onClick={exportPdf}
                  className="p-button-warning mr-2"
                  data-pr-tooltip="PDF"
                />
              </div>
            </div> */}

            <DataTable
              value={registrations}
              ref={dt}
              loading={loading}
              stripedRows
              responsiveLayout="stack"
              header={header1}
              id="p-table"
            >
              <Column body={SN} header="S/N"></Column>
              <Column field="Name" body={nameTemplate} header="Name"></Column>
              <Column
                field="death_certificate_number"
                header="Certificate Number"
              ></Column>

              <Column header="Gender" body={genderTemplate}></Column>
              <Column body={regTypeFormat} header="Registration Type"></Column>
              <Column header="Date registered" body={dater}></Column>
              <Column
                field="Approval_Status"
                body={approvalStatusTemplate}
                header="Status"
              ></Column>
              <Column
                body={(rowData) => (
                  <div>
                    <div className="font-semibold text-xs">
                      {rowData?.registered_byData ? (
                        rowData.registered_byData.UserName
                      ) : (
                        <span className="text-xs">Self</span>
                      )}
                    </div>
                    {rowData?.registered_byData?.Role_ID && (
                      <div className="hidden text-sm text-muted-foreground md:inline">
                        <span className="text-xs">
                          Role: {RoleName(rowData.registered_byData.Role_ID)}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                header="Registered By"
              />


              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={first}
              rows={rows}
              totalRecords={pagination?.total}
              rowsPerPageOptions={[10, 20, 50, 100]}
              // page={page}
              onPageChange={onPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllDeathRegistrations;
