import React from "react";
import './StateCommissioner.scss';

const StatisticCard = ({ stateName, content, stateLogo }) => {
  return (
    <div className="col-12 md:col-6 lg:col-3">
      <div className="surface-0 shadow-2 p-3 border-round-md" style={{ minHeight: '120px', display: 'flex', alignItems: 'center' }}>
        <div className="flex flex-column justify-content-between" style={{ width: '100%' }}>
          <div className="flex align-items-center mb-2">
            <span className="block text-700 font-semibold">{stateName}</span>
          </div>
          <div className="font-bold text-900 text-xl">{content}</div>
          <div className="h-1 bg-green-500 border-round mt-2" style={{height: '5px', width: '80%'}}></div>
        </div>
        <div className="ml-auto">
          <span className="p-2 border-circle m-2 bg-primary font-bold flex align-items-center justify-content-center c-shadow">
            <img className="img-fit c-shadow" height={40} width={40} src={stateLogo} alt={`${stateName} logo`} style={{ borderRadius: '100%' }} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default StatisticCard;
