import { Roles } from "./constants";

export const AccessList = [
  Roles.StateDirector,
  Roles.DCR,
  Roles.Admin,
  Roles.Registrar,
  Roles.SubAdmin,
];

const DcrStateAccessList = [
  "Can_List_Death",
  "Can_List_Birth",
  "Can_List_Modification",
  "Can_List_Attestation",
];
const RegistrarAccessList = [
  "Can_See_Attestation",
  "Can_See_Birth",
  "Can_See_Death",
  "Can_See_Stillbirth",
  "Can_See_Modification",
];

export { DcrStateAccessList, RegistrarAccessList };
