import React, { useEffect, useState } from "react";
import "./../admin.css";
import CardWidget from "../../components/widgets/cards";
import {
    getAllStateCommissionersBirthNotificationsStats,
    getLiveVsStillBirthStatsByMonth,
    getLiveVsStillBirthStatsByYear,
    getBirthNotificationsByGender,
    getAssistedVsSelfStats,
    getTopNotificationsByLGAStats,
    getBirthNotificationByAgeStats,
} from "../../redux/apiCalls";
import { Skeleton } from "primereact/skeleton";
import './StateCommissioner.scss';
import { BarChartComponent } from "../../components/ReusableCharts/BarChart";
import { Dropdown } from "primereact/dropdown";
import BirthRegistrationByGender from "./BirthRegistrationByAge";
import LgaBirthRegistration from "./LgaBirthRegistration";
import { HorizontalBarChartComponent } from "./HorizontalBarChart";
import ReusablePieChart from "./ReusablePieChart";
import { useNavigate } from "react-router-dom";

const BirthNotifications = () => {
    const [statisticsLoading, setStatisticsLoading] = useState(true);
    const [statistics, setStatistics] = useState(null);
    const [liveVsStillBirthStatsStatistics, setLiveVsStillBirthStatsStatistics] = useState(null);
    const [selectedTimeFrame, setSelectedTimeFrame] = useState("Monthly");
    const [genderData, setGenderData] = useState(null);
    const [assistedVsSelfData, setAssistedVsSelfData] = useState(null);
    const [lgaData, setTopRegByLGAStatsfData] = useState(null);
    const [birthNotificationData, setBirthNotificationStatistics] = useState(null);

    const timeFrames = [
        { label: "Monthly", value: "Monthly" },
        { label: "Yearly", value: "Yearly" },
    ];

    const getStatistics = async () => {
        try {
            const data = await getAllStateCommissionersBirthNotificationsStats();
            setStatistics(data);
            setStatisticsLoading(false);
        } catch (error) {
            console.error(error);
            setStatisticsLoading(false);
        }
    };

    const fetchLiveVsStillBirthStats = async () => {
        setStatisticsLoading(true);
        try {
            let data;
            if (selectedTimeFrame === "Monthly") {
                data = await getLiveVsStillBirthStatsByMonth();

                // Sort the data in ascending order by date
                data.sort((a, b) => new Date(a.year_month) - new Date(b.year_month));

                // Map the data to replace year_month with month names
                const monthNames = [
                    "January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];

                const formattedData = data.map(item => {
                    const [year, month] = item.year_month.split("-");
                    return {
                        ...item,
                        year_month: monthNames[parseInt(month, 10) - 1], // Convert month number to month name
                    };
                });

                setLiveVsStillBirthStatsStatistics(formattedData);
            } else {
                // Fetch yearly stats and keep the year as is
                data = await getLiveVsStillBirthStatsByYear();

                // Sort the data in ascending order by year
                data.sort((a, b) => parseInt(a.year_month) - parseInt(b.year_month));

                setLiveVsStillBirthStatsStatistics(data); // No need to modify year
            }
        } catch (error) {
            console.error(error);
        } finally {
            setStatisticsLoading(false);
        }
    };

    const fetchGenderData = async () => {
        setStatisticsLoading(true);
        try {
            const data = await getBirthNotificationsByGender();
            setGenderData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setStatisticsLoading(false);
        }
    };

    const fetchAssistedVsSelfData = async () => {
        setStatisticsLoading(true);
        try {
            const data = await getAssistedVsSelfStats();
            setAssistedVsSelfData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setStatisticsLoading(false);
        }
    };

    const fetchRegByLGAfData = async () => {
        setStatisticsLoading(true);
        try {
            const data = await getTopNotificationsByLGAStats();
            setTopRegByLGAStatsfData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setStatisticsLoading(false);
        }
    };

    const fetchUnder5BirthRegStatsData = async () => {
        setStatisticsLoading(true);
        try {
            const data = await getBirthNotificationByAgeStats();
            setBirthNotificationStatistics(data);
        } catch (error) {
            console.error(error);
        } finally {
            setStatisticsLoading(false);
        }
    };


    useEffect(() => {
        getStatistics();
        fetchGenderData();
        fetchAssistedVsSelfData();
        fetchRegByLGAfData();
        fetchUnder5BirthRegStatsData();
    }, []);

    useEffect(() => {
        fetchLiveVsStillBirthStats();
    }, [selectedTimeFrame]);

    const stats = [
        {
            name: "Total Registrations",
            count: statistics?.total,
            color: "#FFDC27",
        },
        {
            name: "Pending Registrations",
            count: statistics?.Pending,
            color: "#289004",
        },
        {
            name: "Approved Registrations",
            count: statistics?.Approved,
            color: "#551CF6",
        },
        {
            name: "Rejected Registrations",
            count: statistics?.Queried,
            color: "#FF2727",
        },
    ];
    const navigate = useNavigate();

    return (
        <>
            <div className="grid mb-3 px-5">
                <div className="w-full grid md:grid-cols-4 sm:grid-cols-2 gap-4 mt-2 pl-2">
                    {stats.map((item, index) => (
                        <CardWidget loading={statisticsLoading} key={index} name={item.name} color={item.color} count={item.count} />
                    ))}
                </div>

                <div className="col-12 p-3 shadow-2 mt-4" style={{ borderRadius: '6px' }}>
                    <div className="flex justify-content-between align-items-center mb-3">
                        <div className="ml-4 text-sm">Birth Notifications by <span className="font-bold">{selectedTimeFrame}</span></div>
                        <Dropdown value={selectedTimeFrame} options={timeFrames} onChange={(e) => setSelectedTimeFrame(e.value)} placeholder="Select Time Frame" className="p-dropdown-sm" />
                    </div>
                    {statisticsLoading ? (
                        <div className="flex flex-column gap-3">
                            <Skeleton width="100%" height="350px" />
                        </div>
                    ) : (
                        <BarChartComponent
                            data={liveVsStillBirthStatsStatistics || []}
                            bars={[
                                { dataKey: "br", name: "Death Registrations", fill: "#4285F4" },
                            ]}
                        />
                    )}
                </div>

                <div className="flex col-12 gap-4 pr-4 pl-0">
                    <div className="col-12 md:col-3 p-3 shadow-2 mt-4" style={{ borderRadius: '6px' }}>
                        {statisticsLoading ? (
                            <Skeleton width="100%" height="280px" />
                        ) : (
                            <>
                                <div className="text-sm mb-2">Birth Notification by Gender</div>
                                <BirthRegistrationByGender data={genderData || []} />
                            </>
                        )}
                    </div>

                    <div className="col-12 md:col-9 p-3 shadow-2 mt-4" style={{ borderRadius: '6px' }}>
                        {statisticsLoading ? (
                            <Skeleton width="100%" height="280px" />
                        ) : (
                            <>
                                <div className="text-sm mb-4">LGAs with Highest Birth Notifications</div>
                                <LgaBirthRegistration data={lgaData || []} />
                            </>
                        )}
                    </div>
                </div>

                <div className="flex col-12 gap-4 pr-4 pl-0">
                    <div className="col-12 p-3 shadow-2 mt-4" style={{ borderRadius: '6px' }}>
                        {statisticsLoading ? (
                            <Skeleton width="100%" height="280px" />
                        ) : (
                            <>
                                <div className="ml-4 text-sm">Birth Notification By Age</div>
                                <HorizontalBarChartComponent data={birthNotificationData || []} />
                            </>
                        )}
                    </div>

                </div>
            </div>
        </>
    );
};

export default BirthNotifications;
