import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
    Legend,
} from "recharts";
import { roundToNearest } from "../../utils";

function getHighestNumberInArray(dataArray) {
    let highest = 0;

    dataArray.forEach(item => {
        const br = parseInt(item.br, 10);
        const sb = parseInt(item.sb, 10);
        const maxInItem = Math.max(br, sb);
        highest = Math.max(highest, maxInItem);
    });

    return roundToNearest(highest);
}

export const BarChartComponent = ({ data, bars }) => {
    const maxData = getHighestNumberInArray(data);
    return (
        <ResponsiveContainer width="100%" height={480}>
            <BarChart
                barCategoryGap={"0%"}
                barGap={0}
                data={data}
                margin={{ top: 30, right: 20, left: 0, bottom: 15 }}
            >
                <CartesianGrid vertical={false} strokeDasharray="7 7" />
                <XAxis
                    dataKey="year_month"
                    tickLine={false}
                    tick={{ fontSize: 12 }}
                />
                <YAxis
                    allowDecimals={false}
                    axisLine={false}
                    tickLine={false}
                    tick={{ fontSize: 12 }}
                    domain={[0, maxData]}
                />
                <Tooltip cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} />
                <Legend
                    wrapperStyle={{
                        bottom: 0,
                        left: 80,
                    }}
                    align="left"
                    verticalAlign="bottom"
                    iconType="circle"
                    height={36}
                />
                
                {bars.map((bar, index) => (
                    <Bar
                        key={index}
                        dataKey={bar.dataKey}
                        name={bar.name}
                        fill={bar.fill}
                        radius={[10, 10, 0, 0]}
                        barSize={13}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};