import { useEffect, useRef, useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStillRegz } from "../redux/apiCalls";
import { changePage } from "../redux/stillSlice";
import Filter3 from "../components/Filter3";
import { RoleName, SerialNumberTemplate } from "../utils";
import { useTableSerial } from "../helpers/hooks/useTableSerial";
import { useDebounce } from "../helpers/hooks/useDebounce";
const AdminVerified4 = () => {
  const dispatch = useDispatch();
  const [basicFirst, setBasicFirst] = useState(0);
  // eslint-disable-next-line
  const [basicRows, setBasicRows] = useState(20);
  const onBasicPageChange = (event) => {
    // console.log(event)
    setBasicFirst(event.first);
    // setBasicRows(event.rows);
    dispatch(changePage(event.page + 1));
  };
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const loc = useLocation();
  const sp = new URLSearchParams(loc.search); //search?category=cat
  const stateId = parseInt(sp.get("state")) || null;
  const lgaId = parseInt(sp.get("lga")) || null;
  const centerId = parseInt(sp.get("center")) || null;
  const {
    isLoading,
    search,
    result_per_page,
    page,
    Sex,
    Age,
    BirthPlace,
    registerations,
    numPages,
  } = useSelector((state) => state.still);
  const { value: debouncedValue } = useDebounce(search, 1000);
  useEffect(() => {
    getStillRegz(
      dispatch,
      debouncedValue,
      result_per_page,
      page,
      stateId,
      lgaId,
      centerId,
      Sex,
      Age,
      BirthPlace
    );
  }, [
    dispatch,
    debouncedValue,
    result_per_page,
    page,
    stateId,
    lgaId,
    centerId,
    Sex,
    Age,
    BirthPlace,
  ]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(changePage(1));
  }, [debouncedValue, stateId, lgaId, centerId, Sex, Age, BirthPlace]);

  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`/single-still/${rowData.id}`}
      >
        VIEW
      </Link>
    );
  };
  const genderTemplate = (rowData) => {
    return <p className={`font-bold`}>{rowData.deceased_sexData.gender}</p>;
  };

  const birthPlace = (rowData) => {
    return <p className={`font-bold`}>{rowData.birth_typeData.Description}</p>;
  };

  const renderHeader1 = () => {
    return <Filter3 />;
  };
  const header1 = renderHeader1();
  const dt = useRef(null);

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        // Get the DataTable container
        const dataTableContainer = document.getElementById("p-table");

        if (dataTableContainer) {
          // Clone the container to avoid modifying the original content
          const clonedContainer = dataTableContainer.cloneNode(true);

          // Check if the cloned container has a table inside
          const table = clonedContainer.querySelector("table");

          if (table) {
            // Remove undesired elements from the cloned container
            const spansToRemove = clonedContainer.querySelectorAll("td span");
            spansToRemove.forEach((span) => {
              span.parentNode.removeChild(span);
            });

            // Generate PDF using the modified container
            doc.autoTable({ html: table });
            doc.save("still-registrations.pdf");
          } else {
            console.error("Table element not found in cloned container");
          }
        } else {
          console.error("DataTable container not found");
        }
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      // Find the table element by class name
      var table_elt = document.querySelector(".p-datatable-table");

      // Check if the table element is found
      if (table_elt) {
        // Clone the table to avoid modifying the original
        const clonedContainer = table_elt.cloneNode(true);

        // Remove <span> elements within <td> elements
        const spansToRemove = clonedContainer.querySelectorAll("td span");
        spansToRemove.forEach((span) => {
          span.parentNode.removeChild(span);
        });

        // Convert the modified table to a worksheet
        const worksheet = xlsx.utils.table_to_sheet(clonedContainer);

        // Create a workbook
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

        // Convert the workbook to Excel format
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        // Save the Excel file
        saveAsExcelFile(excelBuffer, "still-registrations");
      } else {
        console.error("Table element not found");
      }
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };
  const { serials } = useTableSerial({
    data: registerations,
    currentPage: page,
    pageSize: result_per_page,
  });
  return (
    <>
      <div className="grid mt-2">
        <div className="col-12 lg:col-12">
          <div className="card border-round shadow-2 p-3 ">
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <Button
                label="Go back"
                icon="pi pi-arrow-left"
                className="p-button-sm"
                onClick={goBack}
              />
              <span className="text-xl font-medium text-900">
                {" "}
                Still birth Registration Lists
              </span>
              <div className="flex align-items-center export-buttons">
                {/* <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" /> */}
                <Button
                  type="button"
                  icon="pi pi-file-excel"
                  onClick={exportExcel}
                  className="p-button-success mr-2"
                  data-pr-tooltip="XLS"
                />
                <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  onClick={exportPdf}
                  className="p-button-warning mr-2"
                  data-pr-tooltip="PDF"
                />
                {/* <Button type="button" icon="pi pi-filter" onClick={() => exportCSV(true)} className="p-button-info ml-auto" data-pr-tooltip="Selection Only" /> */}
              </div>
            </div>
            <span className="text-md font-medium text-900">
              Registration List Count:
              {numPages.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
            <DataTable
              value={registerations}
              ref={dt}
              loading={isLoading}
              stripedRows
              responsiveLayout="stack"
              header={header1}
              id="p-table"
            >
              <Column
                field="id"
                header="S/N"
                body={(cell) =>
                  SerialNumberTemplate(cell, serials, registerations)
                }
              ></Column>
              <Column
                field="deceased_village"
                header="Deceased Village"
              ></Column>
              <Column
                field="deceased_date_of_delivery"
                header="Delivery Date"
              ></Column>
              <Column field="" header="birth type" body={birthPlace}></Column>
              <Column field="" header="Gender" body={genderTemplate}></Column>
              <Column field="mother_fullname" header="mother's name"></Column>
              <Column
                field="informant_fullname"
                header="Informant's name"
              ></Column>
              <Column
                body={(rowData) => (
                  <div>
                    <div className="font-semibold text-xs">
                      {rowData?.registered_byData ? (
                        rowData.registered_byData.UserName
                      ) : (
                        <span className="text-xs">Self</span>
                      )}
                    </div>
                    {rowData?.registered_byData?.Role_ID && (
                      <div className="hidden text-sm text-muted-foreground md:inline">
                        <span className="text-xs">
                          Role: {RoleName(rowData.registered_byData.Role_ID)}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                header="Registered By"
              />
              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={basicFirst}
              rows={basicRows}
              totalRecords={numPages}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminVerified4;
