import React from "react";
import './StateCommissioner.scss';

const LgaBirthRegistration = ({ data }) => {
    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    return (
        <>
            <div className="grid gap-2 pl-2">
                {data.map((lga, index) => {
                    const color = generateRandomColor(); // Generate color once for each LGA card
                    const gradientId = `gradient-${index}`; // Unique ID for each gradient

                    return (
                        <div key={index} className="lga-card">
                            <div className="text-sm">{lga.lga}</div>
                            <div className="text-lg font-bold">{lga.registration_count}</div>
                            <div className="lga-line-container">
                                <svg
                                    className="lga-line"
                                    width="96"
                                    height="5"
                                    viewBox="0 0 96 5"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect width="96" height="5" rx="2.5" fill={`url(#${gradientId})`} />
                                    <defs>
                                        <radialGradient
                                            id={gradientId}
                                            cx="0"
                                            cy="0"
                                            r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(48 2.5) rotate(90) scale(2.5 112.412)"
                                        >
                                            <stop stopColor={color} />
                                            <stop
                                                offset="0.62645"
                                                stopColor={color}
                                                stopOpacity="0"
                                            />
                                        </radialGradient>
                                    </defs>
                                </svg>
                                <svg
                                    width="7"
                                    height="7"
                                    viewBox="0 0 7 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="3.5" cy="3.5" r="3.5" fill={color} />
                                </svg>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default LgaBirthRegistration;
