import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ProgressSpinner } from "primereact/progressspinner";
import { getGender, getRegs } from "../redux/apiCalls";
import authFetch from "../axios";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { approvalStatus, RoleName } from "../utils";

const SingleApplication = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { id } = useParams();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [queriedReasons, setQueriedReasons] = useState(null);
  const [img, setImg] = useState("");
  const viewBirth = async () => {
    // /cert/birth/${reg?.Certificate_No}
    setLoading(true);
    try {
      console.log(reg?.Certificate_No);
      const res = await authFetch.get(`/cert/birth/${reg?.Certificate_No}`);
      console.log(res.data);
      setLoading(false);
      setImg(res.data);
      setVisible(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "",
        detail: `${error.response.data.message}`,
      });
    }
  };
  const { isLoading, reg } = useSelector((state) => state.birth);
  useEffect(async () => {
    const response = await getRegs(dispatch, id);
    setQueriedReasons(response.data?.queried);
    // console.log(reg)
  }, [dispatch, id]);

  if (isLoading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }
  return (
    <div>
      <div className="surface-card p-4 shadow-2 border-round">
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm my-2"
          onClick={goBack}
        />
        <div className="border-b-3 border-green-500 mb-4 mt-4">
          <div className="text-gray-600 font-medium text-md uppercase tracking-wide mb-2">
            Registered By
          </div>
          <div className="p-d-flex p-flex-column p-ai-center">
            <div className="text-gray-900 text-xl font-bold">
              {reg?.Registered_ByData?.UserName}
            </div>
            <div>
              {reg?.Registered_ByData?.Role_ID && (
                <div className="text-gray-700 text-lg font-semibold mt-1 lg:mt-0">
                  Role: <span className="text-gray-900 font-normal">{RoleName(reg.Registered_ByData.Role_ID)}</span>
                </div>
              )}
            </div>
          </div>
        </div>


        <div className="flex flex-column lg:flex-row lg:justify-content-between border-bottom-3 border-green-500">
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              BIRTH CERTIFICATE NUMBER
            </div>
            <div className="text-900 text-xl mb-5 font-bold">
              {reg?.Certificate_No ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">STATUS</div>
            <div className="text-900 text-xl mb-5 font-bold">
              {reg?.Approval_Status === approvalStatus.QUERIED
                ? "Queried"
                : reg?.Approval_Status === approvalStatus.APPROVED
                  ? "Approved"
                  : reg?.Approval_Status === approvalStatus.PENDING
                    ? "pending"
                    : ""}
            </div>
          </div>

          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              DATE OF REGISTRATION
            </div>
            <div className="text-900 text-xl mb-5 font-bold">
              {reg?.Date_Registerred?.split("T")[0] ?? ""}
            </div>
          </div>
        </div>

        {reg?.Approval_Status === approvalStatus.APPROVED && (
          <>
            {reg.Approved_ByData?.UserName ? (
              <div className="flex flex-column lg:flex-row lg:justify-content-between border-bottom-3 border-green-500">
                <div className="font-medium text-md text-500 mb-3">
                  Approved By: {reg.Approved_ByData?.UserName}
                </div>{" "}
                <div className="font-medium text-md text-500 mb-3">
                  Phone Number of Approver: {reg.Approved_ByData?.Phone_No}
                </div>
                <div className="font-medium text-md text-500 mb-3">
                  Role: {reg.Approved_ByData?.RoleName}
                </div>
                <div className="font-medium text-md text-500 mb-3">
                  Date Approved:{" "}
                  {new Date(reg?.Date_Approved).toLocaleString("en-GB") ?? ""}
                </div>
              </div>
            ) : (
              <span className="font-bold">Appproved By: System Approved</span>
            )}
          </>
        )}

        {+reg.Approval_Status === approvalStatus.QUERIED && (
          <article className={`flex gap-3 text- text-sm py-2`}>
            <span className="text-sm">
              Queried Reason: {queriedReasons?.reason}
            </span>
          </article>
        )}
      </div>
      <div className="surface-card p-4 shadow-2 border-round my-2">
        <div className="grid grid-nogutter  pt-2">
          <div className="col-12 md:col-4 p-3">
            <div className="text-500 font-medium mb-2">REGISTRATION CENTRE</div>
            <div className="text-900">
              {reg?.Reg_CenterData?.Reg_Center_Name ?? ""}
            </div>
          </div>
          {/* <div className="col-12 md:col-3 p-3">
            <div className="text-500 font-medium mb-2">VILLAGE/TOWN</div>
            <div className="text-900">Demsa</div>
        </div> */}
          <div className="col-12 md:col-4 p-3">
            <div className="text-500 font-medium mb-2">
              LOCAL GOVERNMENT AREA
            </div>

            <div className="text-900">
              {reg?.childData?.birth_lgaData?.LGA_Name ?? ""}
            </div>
          </div>
          <div className="col-12 md:col-4 p-3">
            <div className="text-500 font-medium mb-2">STATE</div>

            <div className="text-900">
              {" "}
              {reg?.childData?.birth_stateData?.State_Name ?? ""}
            </div>
          </div>
        </div>

        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Particulars of child
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">
              {reg?.childData?.firstname ?? ""} {reg?.childData?.surname ?? ""}
            </div>
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Gender</div>

            <div className="text-900">{getGender(reg?.childData?.gender)}</div>
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Place of birth</div>
            <div className="text-900">
              {reg?.Birth_PlaceData?.BirthPlace_Desc ?? ""}
            </div>
          </div>
          {/* <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">LGA</div>
           
            <div className="text-900">{reg&&reg.LGA_of_OriginData?.LGA_Name}</div>
           
        </div> */}

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Birth order</div>

            <div className="text-900">{reg?.Birth_OrderData?.Desc ?? ""}</div>
          </div>
        </div>

        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Particulars of Mother
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">
              {reg?.motherData?.firstname ?? ""}{" "}
              {reg?.motherData?.surname ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">NATIONAL ID NUMBER</div>
            <div className="text-900">{reg?.motherData?.nin ?? ""}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Address</div>
            <div className="text-900">
              {reg?.motherData?.current_address ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Marital Status</div>
            <div className="text-900">
              {reg?.motherData?.current_marital_statusData?.Status_Desc ?? ""}
            </div>

            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">ETHNIC ORIGIN</div>
            <div className="text-900">
              {reg?.motherData?.ethnic_groupData?.Ethnic_Grp_Name ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">NATIONALITY</div>
            <div className="text-900">
              {reg?.motherData?.current_nationalityData?.Country_Name ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">STATE OF ORIGIN</div>
            <div className="text-900">
              {reg?.motherData?.current_residence_stateData?.State_Name ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">LITERACY</div>
            <div className="text-900">
              {reg?.motherData?.current_literacy_levelData?.Literacy ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">LEVEL OF EDUCATION</div>
            <div className="text-900">
              {reg?.motherData?.current_education_levelData?.Description ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">
              Mother's Age at Birth
            </div>
            <div className="text-900">{reg?.mother_age_at_birth ?? ""}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          {/* <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">OCCUPATION</div>
            <div className="text-900">{reg&&reg.mother?.OccupationData?}</div>
            
        </div> */}
        </div>
        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Particulars of father
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">
              {reg?.fatherData?.firstname ?? ""}{" "}
              {reg?.fatherData?.surname ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Phone</div>
            <div className="text-900">
              {reg?.fatherData?.current_phone_number ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">NATIONAL ID NUMBER</div>
            <div className="text-900">{reg?.fatherData?.nin ?? ""}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Address</div>
            <div className="text-900">
              {reg?.fatherData?.current_address ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Marital Status</div>
            <div className="text-900">
              {reg?.fatherData?.current_marital_statusData?.Status_Desc ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">ETHNIC ORIGIN</div>
            <div className="text-900">
              {reg?.fatherData?.ethnic_groupData?.Ethnic_Grp_Name ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">NATIONALITY</div>
            <div className="text-900">
              {reg?.fatherData?.birth_countryData?.Country_Name ?? ""}
            </div>
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">STATE OF ORIGIN</div>
            <div className="text-900">
              {reg?.fatherData?.birth_stateData?.State_Name ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">LITERACY</div>
            <div className="text-900">
              {reg?.fatherData?.current_literacy_levelData?.Literacy ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">LEVEL OF EDUCATION</div>
            <div className="text-900">
              {reg?.fatherData?.current_education_levelData?.Description ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          {/* {
    "user": "keston@email.com",
    "password": "faker00tX",
    "device": "12345673"
}


"user": "reynal-dcr@email.com",
    "password": "test1234",
    "device": "12345676" */}
        </div>
        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Particulars of informant
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">
              {reg?.informantData?.firstname ?? ""}{" "}
              {reg?.informantData?.surname ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Phone</div>
            <div className="text-900">
              {reg?.informantData?.current_phone_number ?? "-"}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">NATIONAL ID NUMBER</div>
            <div className="text-900">{reg.informantData?.nin ?? ""}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Address</div>
            <div className="text-900">
              {reg?.informantData?.current_address ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Relaationship</div>
            <div className="text-900">
              {reg?.Informant_RelationshipData?.Description ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
        </div>
        {reg?.Approval_Status === approvalStatus.APPROVED ? (
          <Button
            label="View Certificate"
            className="p-button-success my-2"
            onClick={viewBirth}
            loading={loading}
          />
        ) : (
          ""
        )}

        {/* <Button
          label="Approve"
          className="p-button-success my-2"
          onClick={viewBirth}
          loading={loading}
        /> */}

        <Toast ref={toast} />

        {/* <div className="card flex justify-content-center">
            <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
        <Dialog
          header="Certificate"
          visible={visible}
          style={{ width: "70vw" }}
          onHide={() => setVisible(false)}
        >
          <img
            src={`data:image/png;base64,${img} `}
            className="w-full"
            alt=""
          />
        </Dialog>
        {/* </div> */}
      </div>
    </div>
  );
};

export default SingleApplication;
