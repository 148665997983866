import { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import {
  Permissions,
  Roles,
  StateCommissionerPermissions,
  TIUPermissions,
  roleOptions,
  statusOptions,
} from "../../helpers/constants";
import { validateFields } from "../../utils";
import _ from "lodash";
import FormLabel from "../../components/FormLabel";
import {
  createUserApi,
  getAllStates,
  getCenterByLgaId,
  getLgaByStateId,
  getPermissions,
} from "../../redux/apiCalls";
import { useSelector } from "react-redux";
import useModuleAccess from "../../helpers/hooks/useModuleAccess";

const CreateUser = () => {
  const toast = useRef(null);
  const { userInfo } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [createLoading, setCreateLoading] = useState(false);
  const hasModuleAccess = useModuleAccess;
  const [credentials, setCredentials] = useState({
    nin: "",
    phone: "",
    username: "",
    email: "",
    state:
      +userInfo.Role_ID === Roles.SubAdmin && userInfo.State_ID
        ? userInfo.State_ID
        : "",
    lga: "",
    center: "",
    role: "",
    status: "",
  });

  const [errors, setErrors] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState("");
  const [state, setStates] = useState([]);
  const [lga, setLga] = useState([]);
  const [center, setCenter] = useState([]);
  const [permissionsWithNameValuePairs, setPermissionsWithNameValuePairs] =
    useState([]);

  let filteredRoleOptions = roleOptions.filter(
    (option) => option.id !== Roles.Adhoc
  );
  useEffect(() => {
    if (credentials.role === Roles.TIU) {
      setSelectedPermission("");
      const updatedPermissions = TIUPermissions.reduce((acc, permission) => {
        const name = permission.replace(/_/g, " ");
        acc.push({ name, code: permission });
        return acc;
      }, []);
      setPermissionsWithNameValuePairs(updatedPermissions);
    } else  if (credentials.role === Roles.StateCommisioner) {
      setSelectedPermission("");
      const updatedPermissions = StateCommissionerPermissions.reduce((acc, permission) => {
        const name = permission.replace(/_/g, " ");
        acc.push({ name, code: permission });
        return acc;
      }, []);
      setPermissionsWithNameValuePairs(updatedPermissions);
    } 
    else {
      const updatedPermissions = permissions.reduce((acc, permission) => {
        const name = permission.replace(/_/g, " ");
        acc.push({ name, code: permission });
        return acc;
      }, []);
      setPermissionsWithNameValuePairs(updatedPermissions);
    }
  }, [credentials.role, permissions]);

  const permissionRoleMapping = {
    [Permissions.canManageAdhocs]: Roles.Adhoc,
    [Permissions.canManageDCR]: Roles.DCR,
    [Permissions.canManageRegistrar]: Roles.Registrar,
    [Permissions.canManageStateDirector]: Roles.StateDirector,
  };

  switch (userInfo.Role_ID) {
    case Roles.SubAdmin.toString():
      // Check if sub admin has a State_ID and apply the respective filter
      if (!userInfo?.State_ID) {
        filteredRoleOptions = filteredRoleOptions.filter((option) =>
          [
            Roles.TIU,
            Roles.SubAdmin,
            Roles.StateDirector,
            Roles.DCR,
            Roles.Registrar,
            Roles.Adhoc,
            Roles.StateCommisioner,
          ].includes(option.id)
        );
      } else {
        filteredRoleOptions = filteredRoleOptions.filter((option) =>
          [
            Roles.SubAdmin,
            Roles.StateDirector,
            Roles.DCR,
            Roles.Registrar,
            Roles.Adhoc,
          ].includes(option.id)
        );
      }
      break;
    case Roles.TIU.toString():
      filteredRoleOptions = filteredRoleOptions.filter((option) =>
        [Roles.StateDirector, Roles.DCR, Roles.Registrar, Roles.Adhoc].includes(
          option.id
        )
      );
      break;
    case Roles.Admin.toString():
      filteredRoleOptions = filteredRoleOptions.filter((option) =>
        [
          Roles.StateDirector,
          Roles.DCR,
          Roles.Registrar,
          Roles.Adhoc,
          Roles.Admin,
          Roles.SubAdmin,
          Roles.TIU,
          Roles.StateCommisioner,
        ].includes(option.id)
      );
      break;
    default:
      filteredRoleOptions = filteredRoleOptions.filter((option) =>
        [].includes(option.id)
      );
      break;
  }

  if (+userInfo.Role_ID === Roles.SubAdmin && userInfo.State_ID) {
    filteredRoleOptions = filteredRoleOptions.filter((option) =>
      [Roles.StateDirector, Roles.DCR, Roles.Registrar, Roles.Adhoc].includes(
        option.id
      )
    );
  }

  if (userInfo) {
    Object.entries(permissionRoleMapping).forEach(([permission, roleId]) => {
      if (
        hasModuleAccess(Permissions.canManageUser) ||
        userInfo.Role_ID === Roles.Admin.toString()
      ) {
        return;
      } else if (!hasModuleAccess(permission)) {
        return (filteredRoleOptions = filteredRoleOptions.filter(
          (option) => option.id !== roleId
        ));
      }
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleRoleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };
  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };
  const hanldeSubmitUser = async (e) => {
    e.preventDefault();
    const res = await validateFields({ ...credentials });
    const roleFieldsMapping = {
      [Roles.DCR]: [
        "role",
        "email",
        "status",
        "username",
        "phone",
        "state",
        "lga",
        "nin",
      ],
      [Roles.Registrar]: [
        "email",
        "role",
        "status",
        "username",
        "phone",
        "state",
        "lga",
        "center",
        "nin",
      ],
      [Roles.StateDirector]: [
        "email",
        "role",
        "status",
        "username",
        "phone",
        "state",
        "nin",
      ],
      [Roles.StateCommisioner]: [
        "email",
        "role",
        "status",
        "username",
        "phone",
        "state",
      ],
      default: ["role", "email", "username", "phone", "status", "nin"],
    };
    const roleFields =
      roleFieldsMapping[credentials.role] || roleFieldsMapping.default;
    const tempErrors = Object.fromEntries(
      Object.entries(res).filter(([key]) => roleFields.includes(key))
    );

    setErrors(tempErrors);

    if (_.isEmpty(tempErrors)) {
      setCreateLoading(true);
      try {
        const createUserResponse = await createUserApi({
          UserName: credentials.username,
          Email: credentials.email,
          Phone_No: credentials.phone,
          NIN: credentials.nin,
          Role_ID: credentials.role,
          State_ID: +credentials.state || null,
          LGA_ID: +credentials.lga || null,
          is_active: credentials?.status,
          Reg_Center_ID: +credentials.center || null,
          permissions: selectedPermission
            .map((permission) => permission.code)
            .join(","),
        });
        setCreateLoading(false);

        if (createUserResponse.success === true) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: `${createUserResponse.message ?? "Record created"}`,
          });
          navigate("/user-access");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: `${createUserResponse.message ?? "record not created"}`,
          });
        }
      } catch (error) {
        setCreateLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${error.response.data.message ?? "record not created"}`,
        });
        console.log(error);
      }
    }
  };
  const permissionsList = async () => {
    try {
      const data = await getPermissions();
      setPermissions(data);
    } catch (error) {
      console.log(error);
    }
  };
  const statesList = async () => {
    try {
      const data = await getAllStates();
      setStates(data);
    } catch (error) {
      console.log(error);
    }
  };

  const LgaList = async (payload) => {
    try {
      const data = await getLgaByStateId(payload);
      setLga(data);
    } catch (error) {
      console.log(error);
    }
  };
  const CenterList = async (payload) => {
    try {
      const data = await getCenterByLgaId(payload);
      setCenter(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    permissionsList();
    statesList();
  }, []);

  useEffect(() => {
    if (credentials.state) {
      LgaList(credentials.state);
    }
  }, [credentials.state]);

  useEffect(() => {
    if (credentials.lga) {
      CenterList(credentials.lga);
    }
  }, [credentials.lga]);

  return (
    <div className="grid mt-2 p-4">
      <div className="col-12 lg:col-12">
        <div className="card border-round shadow-2 p-3">
          <Button
            label="Go back"
            icon="pi pi-arrow-left"
            className="p-button-sm my-3"
            onClick={goBack}
          />
          <h5 className=" capitalize border-green-500 border-bottom-3 text-lg font-bold mb-3">
            Create User Account
          </h5>
          <form onSubmit={hanldeSubmitUser} className="formgrid grid">
            <div className="field col-12  md:col-4">
              <FormLabel
                required
                label="Full Name"
                type="username"
                errors={errors}
              />
              <InputText
                name="username"
                id="username"
                maxLength={153}
                value={credentials.username}
                onChange={handleChange}
                keyfilter={/^[A-Za-z\s]*$/}
                // keyfilter={/^[^<>*!]+$/}
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              />
            </div>
            <div className="field col-12 md:col-4">
              <FormLabel
                required
                label="Phone Number"
                type="phone"
                errors={errors}
              />
              <InputText
                onChange={handleChange}
                name="phone"
                id="phone"
                keyfilter="num"
                value={credentials.phone}
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                maxLength={11}
              />
            </div>

            <div className="field col-12 md:col-4">
              <FormLabel required label="NIN" type="nin" errors={errors} />
              <InputText
                onChange={handleChange}
                name="nin"
                id="nin"
                value={credentials.nin}
                keyfilter="num"
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                maxLength={11}
              />
            </div>
            <div className="field col-12 md:col-4">
              <FormLabel
                required
                label="Email Address"
                type="email"
                errors={errors}
              />
              <InputText
                onChange={handleChange}
                name="email"
                id="email"
                value={credentials.email}
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              />
            </div>
            <div className="field col-12 md:col-4">
              <FormLabel required label="Role" type="role" errors={errors} />
              <Dropdown
                value={credentials.role}
                options={filteredRoleOptions}
                onChange={handleRoleChange}
                name="role"
                id="role"
                className="w-full"
                optionLabel="name"
                optionValue="id"
                placeholder="Select Role"
              />
            </div>
            <div className="field col-12 md:col-4">
              <FormLabel
                required
                label="Status"
                type="status"
                errors={errors}
              />
              <Dropdown
                value={credentials.status}
                options={statusOptions}
                onChange={handleStatusChange}
                name="status"
                id="status"
                className="w-full"
                optionLabel="name"
                optionValue="id"
                placeholder="Select Status"
              />
            </div>

            {[
              Roles.Registrar,
              Roles.DCR,
              Roles.StateDirector,
              Roles.SubAdmin,
              Roles.Adhoc,
              Roles.StateCommisioner,
            ].includes(+credentials.role) && (
              <div className="field col-12 md:col-4">
                <FormLabel
                  required={credentials.role !== Roles.SubAdmin}
                  label="State"
                  type="state"
                  errors={errors}
                />
                {/* <select
                  id="state"
                  value={credentials.state}
                  name="state"
                  className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                  onChange={handleChange}
                  style={{ appearance: "auto" }}
                >
                  <option value="">Select State</option>
                  {state.map((item) => (
                    <option value={item.State_ID} key={item.State_ID}>
                      {item.State_Name}
                    </option>
                  ))}
                </select> */}
                {!(
                  +userInfo.Role_ID === Roles.SubAdmin && userInfo.State_ID
                ) ? (
                  <Dropdown
                    value={credentials.state}
                    options={state}
                    onChange={handleChange}
                    name="state"
                    id="state"
                    className="w-full"
                    optionLabel="State_Name"
                    optionValue="State_ID"
                    placeholder="Select State"
                  />
                ) : (
                  <InputText
                    name="email"
                    disabled
                    id="email"
                    value={userInfo?.State?.State_Name}
                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                  />
                )}
              </div>
            )}

            {[Roles.Registrar, Roles.DCR, Roles.Adhoc].includes(
              credentials.role
            ) && (
              <div className="field col-12 md:col-4">
                <FormLabel required label="LGA" type="lga" errors={errors} />
                <select
                  id="lga"
                  value={credentials.lga}
                  name="lga"
                  onChange={handleChange}
                  className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                  style={{ appearance: "auto" }}
                >
                  <option value="">select lga</option>
                  {lga.map((item, i) => {
                    return (
                      <option value={item.LGA_ID} key={i}>
                        {item.LGA_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            {[Roles.Registrar, Roles.Adhoc].includes(credentials.role) && (
              <div className="field col-12 md:col-4">
                <FormLabel
                  required
                  label="Center"
                  type="center"
                  errors={errors}
                />
                <select
                  id="center"
                  value={credentials.center}
                  onChange={handleChange}
                  name="center"
                  className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                  style={{ appearance: "auto" }}
                >
                  <option value="">select center</option>
                  {center.map((item, i) => {
                    return (
                      <option value={item.Reg_Center_id} key={i}>
                        {item.Reg_Center_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            <div className="formgrid grid px-2">
              <div className="field col-12 md:col-4">
                <label htmlFor="firstname6">
                  Permissions <small className="text-red-500">*</small>
                </label>

                <MultiSelect
                  value={selectedPermission}
                  onChange={(e) => {
                    setSelectedPermission(e.value);
                  }}
                  options={permissionsWithNameValuePairs}
                  optionLabel="name"
                  placeholder="Select user permissions"
                  maxSelectedLabels={3}
                  className="w-20rem text-sm"
                />
              </div>
            </div>
            <div className="field col-12 ">
              <Button
                label={createLoading ? "Creating Account" : "Create Account"}
                type="submit"
                loading={createLoading}
                className="p-button-success my-2"
              />
            </div>
            <Toast ref={toast} position="top-center" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
